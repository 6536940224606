@charset "UTF-8";
* {
  -webkit-font-smoothing: antialiased;
}

main .wpb_text_column .wpb_wrapper a {
  text-decoration: none;
  border-bottom: 1px solid;
}
main .wpb_text_column .wpb_wrapper .g-breadcrumbs a {
  border-bottom: none;
}

.single-post .post_custom_field.intro a {
  text-decoration: none;
  border-bottom: 1px solid;
}

@media screen and (max-width: 600px) {
  .l-section.height_medium {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
}
@media screen and (max-width: 1024px) {
  .tablet--fullwidth {
    grid-template-columns: 12fr !important;
  }
}
header {
  background-color: #ffffff;
  background-image: url("../images/header-bg.svg");
  background-size: auto 80%;
  background-position: center bottom;
  background-repeat: repeat-x;
}
header .l-subheader {
  background: transparent !important;
}
header .l-subheader.at_top ul li a:not(.w-btn):hover {
  text-decoration: underline;
}
header .l-subheader.at_top ul li:hover a:not(.w-btn) {
  color: inherit !important;
}
header nav .w-nav-icon {
  color: #056938;
}
header nav ul li > .w-btn {
  margin-right: 0px !important;
}
header nav ul li .w-nav-arrow {
  font-size: 0.7rem;
}
header nav ul.level_1 ul li a {
  font-weight: 400;
}
@media screen and (max-width: 1024px) {
  header .source_wpml .w-dropdown-h {
    display: flex;
    gap: 1rem;
  }
  header .source_wpml .w-dropdown-h .w-dropdown-current .w-dropdown-item .w-dropdown-item-title {
    text-decoration: underline;
  }
  header .source_wpml .w-dropdown-h .w-dropdown-item {
    padding: 0 !important;
  }
  header .source_wpml .w-dropdown-h .w-dropdown-item:after {
    display: none;
  }
  header .source_wpml .w-dropdown-h .w-dropdown-item .w-dropdown-item-title {
    font-size: 14px;
    line-height: 14px;
    color: #056938;
  }
  header .source_wpml .w-dropdown-h .w-dropdown-list {
    transform: none !important;
    opacity: 1 !important;
    position: relative !important;
    background: transparent !important;
    transition: none !important;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;
    border: none !important;
    top: 0 !important;
    left: 0 !important;
  }
  header .source_wpml .w-dropdown-h .w-dropdown-list .w-dropdown-list-h {
    display: flex;
    gap: 1rem;
  }
}

html.w-nav-open header nav.type_mobile ul.level_1 {
  height: 100vh !important;
}

@media screen and (max-width: 1024px) {
  header .l-subheader.at_middle .l-subheader-h {
    z-index: 999;
  }
  header .l-subheader.at_middle .l-subheader-h ul.w-nav-list.level_1 {
    z-index: -1;
    min-height: calc(100vh - 60px);
    min-width: 100vw;
    margin-top: 60px;
    background: #056938;
    padding: 1rem 1.5rem;
  }
  header .l-subheader.at_middle .l-subheader-h ul.w-nav-list.level_1 ul.w-nav-list {
    min-height: 100vh;
    min-width: 100vw;
    transform: translateX(100%);
    right: 0;
    top: 0;
    bottom: 0;
    box-shadow: -1px 0 0 rgba(0, 0, 0, 0.08);
    position: fixed;
    display: block;
    padding: 1rem 1.5rem !important;
    margin: 0;
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 0;
    background: #056938 !important;
    color: #ffffff;
  }
  header .l-subheader.at_middle .l-subheader-h ul.w-nav-list.level_1 li.opened > ul.w-nav-list {
    transform: translateX(0%);
  }
  header .l-subheader.at_middle .l-subheader-h ul.w-nav-list.level_1 li.menu-item {
    border: none !important;
  }
  header .l-subheader.at_middle .l-subheader-h ul.w-nav-list.level_1 li.menu-item a:not(.w-btn) {
    color: #ffffff !important;
    padding: 0.5rem 0 !important;
    line-height: 1.6em !important;
    font-size: 14px !important;
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
    font-weight: 400;
  }
  header .l-subheader.at_middle .l-subheader-h ul.w-nav-list.level_1 li.menu-item a:not(.w-btn):after {
    display: none !important;
  }
  header .l-subheader.at_middle .l-subheader-h ul.w-nav-list.level_1 li.menu-item a:not(.w-btn) .w-nav-title:before {
    display: none !important;
  }
  header .l-subheader.at_middle .l-subheader-h ul.w-nav-list.level_1 li.menu-item a:not(.w-btn) .w-nav-arrow:before {
    font-weight: 400;
  }
  header .l-subheader.at_middle .l-subheader-h ul.w-nav-list.level_1 li.menu-item.nav--button {
    position: absolute;
    bottom: 70px;
    left: 0;
    width: 100%;
    height: 60px;
  }
  header .l-subheader.at_middle .l-subheader-h ul.w-nav-list.level_1 li.menu-item.nav--button .w-btn {
    width: 100%;
    margin: 0;
    padding: 1rem;
    height: 60px;
    border-radius: 0;
    line-height: 1.8 !important;
    font-weight: 700;
    font-size: 1rem;
  }
  header .l-subheader.at_middle .l-subheader-h ul.w-nav-list.level_1 .w-nav-arrow:before {
    content: "\f054";
    font-weight: 700;
    font-size: 1rem;
  }
  header .l-subheader.at_middle .l-subheader-h ul.w-nav-list.level_1 .menu--goback {
    font-size: 14px;
    display: block;
    width: 100%;
    position: relative;
    color: #ffffff;
    padding: 0.5rem 0;
    padding-left: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
    cursor: pointer;
    font-weight: 700;
    line-height: 1.5;
  }
  header .l-subheader.at_middle .l-subheader-h ul.w-nav-list.level_1 .menu--goback:after {
    content: "\f053";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    text-decoration: inherit;
    color: #ffffff;
    font-size: 1rem;
    position: absolute;
    top: 0.3rem;
    left: 0;
  }
  header .menu--button {
    display: inline-block !important;
    padding: 5px !important;
    margin-top: 0 !important;
    line-height: 1 !important;
  }
  header .menu--button a {
    margin: 0 !important;
  }
}
footer .wpb_text_column .wpb_wrapper p a:hover {
  text-decoration: underline;
}
footer #footer--sub .wrapper {
  gap: 1.5rem;
}
footer .w-iconbox-icon {
  margin-right: 0.3rem !important;
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  footer #footer-menu {
    display: flex;
    flex-wrap: wrap;
  }
  footer #footer-menu .vc_column_container {
    width: calc(25% - 3rem);
  }
  footer #footer-menu .vc_column_container:nth-child(3) {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  footer #footer-menu {
    display: flex;
    flex-wrap: wrap;
  }
  footer #footer-menu .vc_column_container {
    width: calc(50% - 3rem);
  }
}
footer .newsletter-footer {
  position: relative;
}
footer .newsletter-footer .emailaddress .ginput_container input#input_8_1, footer .newsletter-footer .emailaddress .ginput_container input#input_14_1, footer .newsletter-footer .emailaddress .ginput_container input#input_11_1 {
  padding-right: 115px !important;
  padding-left: 10px !important;
  font-size: 14px !important;
  line-height: 40px !important;
  min-height: 45px !important;
}
footer .newsletter-footer input[type=submit] {
  font-size: 14px !important;
  min-width: 100px !important;
}
footer .newsletter-footer .privacy-policy {
  font-size: 12px !important;
}

.section--bg-wave .l-section-img {
  background-position: center calc(100% + 80px) !important;
  background-repeat: no-repeat !important;
  background-size: 2800px !important;
}
.section--bg-wave.bg--higher .l-section-img {
  background-position: center calc(100% + 50px) !important;
}
@media screen and (max-width: 767px) {
  .section--bg-wave.bg--higher .l-section-img {
    background-position: center calc(100% + 70px) !important;
  }
}

.g-breadcrumbs {
  font-size: 14px;
}
.g-breadcrumbs .g-breadcrumbs-separator {
  font-size: 12px;
  margin-top: 2px;
}
.g-breadcrumbs .g-breadcrumbs-item:not(:last-child) {
  text-decoration: underline;
}
.g-breadcrumbs .w-btn-wrapper {
  margin: 0.1rem 0 !important;
}
.g-breadcrumbs .w-btn-wrapper .w-btn {
  font-size: 14px !important;
}
.g-breadcrumbs .w-btn-wrapper .w-btn .w-btn-label {
  text-decoration: underline;
}
.g-breadcrumbs .w-btn-wrapper .w-btn i {
  font-size: 12px !important;
  margin-top: 2px;
  margin: 0 0.6em;
}
.g-breadcrumbs a:hover .w-btn-label {
  border: none !important;
  color: #056938 !important;
}

.w-btn.us-btn-style_11, .w-btn.us-btn-style_12, .w-btn.us-btn-style_13 {
  display: block;
}
.w-btn.us-btn-style_11:hover .w-btn-label, .w-btn.us-btn-style_12:hover .w-btn-label, .w-btn.us-btn-style_13:hover .w-btn-label {
  border-bottom: 1px solid;
}
.w-btn.us-btn-style_14 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.w-btn.us-btn-style_14 .w-btn-label {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
.w-btn.us-btn-style_14 .w-btn-label:before {
  content: "";
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 0.8rem;
  background-image: url("../images/question-mark.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.w-btn.us-btn-style_14 i {
  font-size: 0.8rem;
}
.w-btn.us-btn-style_15 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.w-btn.us-btn-style_15 .w-btn-label {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
.w-btn.us-btn-style_15 .w-btn-label:before {
  content: "";
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 0.8rem;
  background-image: url("../images/question-mark-green.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.w-btn.us-btn-style_15 i {
  font-size: 0.8rem;
}

#blog--categories .active .w-btn {
  background-color: #333333;
}

.owl-carousel {
  padding-left: 60px !important;
  padding-right: 60px !important;
}
.owl-carousel .owl-nav:not(.disabled) {
  display: block !important;
}
.owl-carousel .owl-nav button {
  border-width: 0px !important;
  width: 40px !important;
  height: 40px !important;
  background: #EAEAEA !important;
  border-radius: 50% !important;
}
.owl-carousel .owl-nav button.owl-prev {
  left: 0 !important;
  right: auto !important;
}
.owl-carousel .owl-nav button.owl-next {
  right: 0 !important;
  left: auto !important;
}

.carousel--logos img {
  max-height: 65px;
  width: auto !important;
  margin: 0 auto;
  max-width: 100%;
  display: block;
}

.menu--view {
  background: #ffffff;
  border-radius: 0.5rem;
  padding: 1rem;
}
.menu--view * {
  color: #056938 !important;
}
.menu--view ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0 1rem;
}
.menu--view ul li {
  background-color: #ffffff;
  width: calc(50% - 0.5rem);
  margin: 0;
}
@media screen and (max-width: 600px) {
  .menu--view ul li {
    width: 100%;
  }
}
@media screen and (min-width: 601px) {
  .menu--view ul li:nth-child(4n+1), .menu--view ul li:nth-child(4n+2) {
    background-color: #F6F6F6;
  }
}
@media screen and (max-width: 600px) {
  .menu--view ul li:nth-child(odd) {
    background-color: #F6F6F6;
  }
}
.menu--view ul li:hover {
  background-color: #e6f0ec;
}
.menu--view ul li a {
  padding: 7px 15px;
  width: 100%;
  display: block;
  position: relative;
  padding-right: calc(0.8rem + 15px);
}
.menu--view ul li a:after {
  content: "\f054";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: inherit;
  font-size: 0.75rem;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.menu--view-black {
  background: transparent;
  border-radius: 0;
  padding: 0;
}
.menu--view-black * {
  color: #ffffff !important;
}
.menu--view-black ul li {
  width: 100% !important;
  background: #000000 !important;
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
  margin: 0.1rem 0;
  color: #ffffff !important;
}
.menu--view-black ul li:hover {
  background: #323232 !important;
}
.menu--view-black ul li a {
  text-align: left;
  border: none !important;
}

/* GASSTATION FINDER */
.form--gasstation-finder {
  position: relative;
}
.form--gasstation-finder input[type=text] {
  padding-right: 155px;
  min-height: 50px;
  line-height: 50px;
}
.form--gasstation-finder input[type=submit] {
  position: absolute;
  top: 4px;
  right: 5px;
  min-width: 140px;
}
.form--gasstation-finder .suggestion-box {
  border: 1px solid #A3A3A3;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  background-color: #ffffff;
  width: calc(100% - 2px); /* Adjust width according to input field */
  z-index: 1000;
  display: none;
}
.form--gasstation-finder .suggestion-box .suggestion-item {
  padding: 10px;
  cursor: pointer;
}
.form--gasstation-finder .suggestion-box .suggestion-item:hover {
  background-color: #F6F6F6;
}

/* NEWSLETTER - FOOTER */
.newsletter-footer {
  position: relative;
}
.newsletter-footer .emailaddress input#input_8_1, .newsletter-footer .emailaddress input#input_14_1, .newsletter-footer .emailaddress input#input_11_1 {
  padding-right: 155px !important;
  min-height: 50px;
}
.newsletter-footer input[type=submit] {
  position: absolute;
  top: 4px;
  right: 5px;
  min-width: 140px;
}
.newsletter-footer .privacy-policy {
  font-size: 14px;
}
.newsletter-footer .privacy-policy a {
  text-decoration: underline;
}
.newsletter-footer .privacy-policy a:hover {
  font-weight: 500;
}

.gform_wrapper form.newsletter-footer .gform_body ul.gform_fields .gfield {
  padding: 0 !important;
  margin-bottom: 0.5rem !important;
}
.gform_wrapper form.newsletter-footer .gform_body ul.gform_fields .gfield .ginput_container label {
  display: inline-block !important;
  font-weight: 400;
}

/* GRAVITY FORMS */
.color_alternate .gform_confirmation_message, footer .gform_confirmation_message {
  color: #ffffff;
}

.form--box-container form {
  background-color: #ffffff;
  padding: 3rem;
  border-radius: 2rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 767px) {
  .form--box-container form {
    padding: 3rem 0.5rem;
  }
}

.gform_wrapper .validation_error {
  display: none;
}
.gform_wrapper .gf_progressbar {
  box-shadow: none;
  border: 1px solid #A3A3A3;
  margin-bottom: 1.5rem;
  height: 1.5rem;
}
.gform_wrapper .gf_progressbar .gf_progressbar_percentage {
  padding: 0.25rem 0.5rem;
}
.gform_wrapper form .gform_body ul.gform_fields {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.gform_wrapper form .gform_body ul.gform_fields .hidden_label > label, .gform_wrapper form .gform_body ul.gform_fields .hide-label > label {
  display: none !important;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield_visibility_ {
  display: none;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield {
  width: 100%;
  margin-bottom: 2rem !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  position: relative;
  max-width: 100%;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield.half {
  width: 50%;
}
@media (max-width: 767px) {
  .gform_wrapper form .gform_body ul.gform_fields .gfield.half {
    width: 100%;
  }
}
.gform_wrapper form .gform_body ul.gform_fields .gfield.gform_hidden {
  margin-bottom: 0 !important;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield label {
  margin-bottom: 0.5rem;
  display: block;
  font-weight: 500;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield .gfield_description {
  display: block;
  margin-top: 0.5rem;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield .instruction {
  display: none;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield .ginput_counter {
  display: none;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield .gfield_radio,
.gform_wrapper form .gform_body ul.gform_fields .gfield .gfield_checkbox {
  margin: 0;
  padding: 0;
  list-style: none;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield .gfield_radio label,
.gform_wrapper form .gform_body ul.gform_fields .gfield .gfield_checkbox label {
  font-weight: 400;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield .gfield_radio li,
.gform_wrapper form .gform_body ul.gform_fields .gfield .gfield_checkbox li {
  padding: 9px 0 0;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield .gfield_radio li:first-child,
.gform_wrapper form .gform_body ul.gform_fields .gfield .gfield_checkbox li:first-child {
  padding-top: 0;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield .gfield_radio {
  margin-left: -15px;
  margin-right: -15px;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield .gfield_radio li {
  flex: 0 0 225px;
  padding: 3px 15px;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield .gfield_radio li label {
  display: inline;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield.gf_readonly input {
  background: #F6F6F6;
  pointer-events: none;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield .validation_message {
  font-size: 14px;
  color: #e2251d;
  background: #ffffff !important;
  padding: 0 5px !important;
  border-radius: 5px;
  display: inline-block;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield ul.gfield_checkbox input {
  display: none;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield ul.gfield_checkbox input + label {
  position: relative;
  padding-left: 40px;
  display: inline-block;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield ul.gfield_checkbox input + label:before {
  content: "";
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid #e9e9e7;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.gform_wrapper form .gform_body ul.gform_fields .gfield ul.gfield_checkbox input + label a {
  display: inline-table;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield ul.gfield_checkbox input:checked + label:before {
  border: 1px solid #056938;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield ul.gfield_checkbox input:checked + label:after {
  content: "";
  position: absolute;
  left: 7px;
  background: none;
  width: 10px;
  height: 15px;
  border: 2px solid #056938;
  border-radius: 0;
  border-top: none;
  border-left: none;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(40deg);
  transform: translateY(-10px) rotate(40deg);
}
.gform_wrapper form .gform_body ul.gform_fields .gfield .gform-field-label a {
  text-decoration: underline;
  border: none !important;
}
.gform_wrapper form .gform_body ul.gform_fields .gfield .gform-field-label a:hover {
  font-weight: 500;
}
.gform_wrapper form .gf_page_steps {
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
}
.gform_wrapper form .gf_page_steps .gf_step {
  padding: 0 1rem;
  position: relative;
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  flex: 1 1 0px;
}
@media screen and (max-width: 767px) {
  .gform_wrapper form .gf_page_steps .gf_step {
    display: none !important;
    text-align: center;
  }
  .gform_wrapper form .gf_page_steps .gf_step.gf_step_active {
    display: block !important;
  }
}
.gform_wrapper form .gf_page_steps .gf_step .gf_step_number {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #E6E6E6;
  color: #151515;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
@media screen and (max-width: 767px) {
  .gform_wrapper form .gf_page_steps .gf_step .gf_step_number {
    margin-left: auto;
    margin-right: auto;
  }
}
.gform_wrapper form .gf_page_steps .gf_step.gf_step_active .gf_step_number {
  background-color: #056938;
  color: #ffffff;
}
.gform_wrapper form .gf_page_steps .gf_step:before {
  content: "";
  position: absolute;
  left: 0;
  top: 25px;
  width: calc(50% - 35px);
  height: 1px;
  background: #e9e9e7;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .gform_wrapper form .gf_page_steps .gf_step:before {
    display: none;
  }
}
.gform_wrapper form .gf_page_steps .gf_step:first-child:before {
  display: none;
}
.gform_wrapper form .gf_page_steps .gf_step:after {
  content: "";
  position: absolute;
  right: 0;
  top: 25px;
  width: calc(50% - 35px);
  height: 1px;
  background: #e9e9e7;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .gform_wrapper form .gf_page_steps .gf_step:after {
    display: none;
  }
}
.gform_wrapper form .gf_page_steps .gf_step:last-child:after {
  display: none;
}
.gform_wrapper form .review-table-holder .review-line {
  display: flex;
  border-bottom: 1px solid #dddddd;
}
@media screen and (max-width: 600px) {
  .gform_wrapper form .review-table-holder .review-line {
    flex-wrap: wrap;
  }
}
.gform_wrapper form .review-table-holder .review-line span {
  display: block;
  font-size: 15px;
  line-height: 44px;
}
.gform_wrapper form .review-table-holder .review-line span.title {
  font-weight: 500;
  flex: 1 0 40%;
}
@media screen and (max-width: 600px) {
  .gform_wrapper form .review-table-holder .review-line span.title {
    flex: 1 0 100%;
    line-height: 1.2em;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
.gform_wrapper form .review-table-holder .review-line span.value {
  flex: 1 0 60%;
}
@media screen and (max-width: 600px) {
  .gform_wrapper form .review-table-holder .review-line span.value {
    flex: 1 0 100%;
    line-height: 1.2em;
    margin-bottom: 10px;
  }
}
.gform_wrapper form .review-table-holder .review-line:last-child {
  border-bottom: none;
}
.gform_wrapper form .gform_page_footer, .gform_wrapper form .gform_footer {
  text-align: left;
}
.gform_wrapper form .gform_page_footer input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]):not([type=submit]), .gform_wrapper form .gform_footer input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]):not([type=submit]) {
  line-height: 1.2 !important;
  min-height: 40px !important;
  padding: 0.7em 1em;
}
.gform_wrapper form .gform_previous_button,
.gform_wrapper form .gform_next_button {
  font-size: 1rem;
  line-height: 1.2 !important;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0em;
  border-radius: 0.5rem;
  padding: 0.7em 1em;
  border: none;
  min-height: 0;
}

/* MAZOUT FORM */
.form--mazout-container .gform_heading .gform_required_legend {
  display: none;
}
.form--mazout-container .gform_validation_errors {
  display: none;
}

.form--mazout {
  position: relative;
}
.form--mazout .gf_page_steps {
  border: none !important;
}
.form--mazout .gf_page_steps .gf_step {
  margin: 0 !important;
}
.form--mazout .gf_page_steps .gf_step .gf_step_number {
  width: 20px !important;
  height: 20px !important;
  background-color: #323232 !important;
  color: #ffffff !important;
  font-size: 12px !important;
  margin-bottom: 0 !important;
  line-height: 1 !important;
  position: absolute !important;
  bottom: 0 !important;
  left: calc(50% - 10px) !important;
  border: 1px solid #323232 !important;
}
.form--mazout .gf_page_steps .gf_step .gf_step_number:before, .form--mazout .gf_page_steps .gf_step .gf_step_number:after {
  display: none;
}
.form--mazout .gf_page_steps .gf_step .gf_step_label {
  margin-bottom: 25px !important;
  font-weight: 400;
  color: #056938;
}
.form--mazout .gf_page_steps .gf_step.gf_step_active .gf_step_number {
  background-color: #ffffff !important;
  color: #056938 !important;
  border: 1px solid #056938 !important;
}
.form--mazout .gf_page_steps .gf_step:after {
  top: auto !important;
  bottom: 10px !important;
  width: calc(50% - 20px) !important;
  background: #C1C1C1 !important;
}
.form--mazout .gf_page_steps .gf_step:before {
  top: auto !important;
  bottom: 10px !important;
  width: calc(50% - 20px) !important;
  background: #C1C1C1 !important;
}
.form--mazout .gform_page {
  margin-top: 4rem;
}
.form--mazout .gform_page .gform_page_fields {
  padding: 2.5rem 2.5rem 1rem 2.5rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
.form--mazout .gform_page_footer input[type=button] {
  border-radius: 3rem !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  padding: 0.7rem 1.5rem !important;
}
.form--mazout .gform-body .gfield {
  margin-bottom: 1rem !important;
}
.form--mazout h5 {
  font-weight: 700 !important;
}
.form--mazout .gfield_label {
  font-weight: 400 !important;
}
.form--mazout .gfield_error legend, .form--mazout .gfield_error label {
  color: #1a1a1a !important;
}
.form--mazout .gfield_error .gfield_description {
  font-size: 13px !important;
}
.form--mazout .mazout--product .gfield_radio {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}
.form--mazout .mazout--product .gfield_radio .gchoice {
  position: relative;
}
.form--mazout .mazout--product .gfield_radio .gchoice label {
  background-color: #ffffff;
  color: #056938 !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  border-radius: 10px;
  padding: 0.7rem 1rem;
  white-space: nowrap;
  max-width: none;
  font-weight: 700;
  font-size: 1.15rem;
}
.form--mazout .mazout--product .gfield_radio .gchoice input {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
}
.form--mazout .mazout--product .gfield_radio .gchoice input[type=radio]:checked + label {
  background-color: #056938;
  color: #ffffff !important;
}
.form--mazout .zip-input {
  display: none;
}
.form--mazout #suggestions-container {
  position: relative;
  margin-top: -1rem;
}
.form--mazout #suggestions-container .suggestion-box {
  border: 1px solid #A3A3A3;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  background-color: #ffffff;
  width: calc(100% - 2px); /* Adjust width according to input field */
  z-index: 1000;
  display: none;
}
.form--mazout #suggestions-container .suggestion-box .suggestion-item {
  padding: 10px;
  cursor: pointer;
}
.form--mazout #suggestions-container .suggestion-box .suggestion-item:hover {
  background-color: #F6F6F6;
}
.form--mazout .mazout--betaling .gfield_radio {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  flex-wrap: wrap;
}
.form--mazout .mazout--betaling .gfield_radio .gchoice {
  position: relative;
}
.form--mazout .mazout--betaling .gfield_radio .gchoice label {
  background-color: #ffffff;
  color: #393939 !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  border-radius: 10px;
  padding: 0.7rem 1rem;
  white-space: nowrap;
  max-width: none;
  font-weight: 700;
  font-size: 1.15rem;
}
.form--mazout .mazout--betaling .gfield_radio .gchoice input {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
}
.form--mazout .mazout--betaling .gfield_radio .gchoice input[type=radio]:checked + label {
  background-color: #056938;
  color: #ffffff !important;
}
.form--mazout .clientnumber--aware {
  margin-top: 2rem !important;
}
.form--mazout .clientnumber--aware .validation_message {
  display: none !important;
}
.form--mazout .gform-body .gfield.mollie--total-price,
.form--mazout .gform-body .gfield.mollie--total-price-product {
  opacity: 0;
  height: 0;
  margin-bottom: 0 !important;
}
.form--mazout .gform-body .gfield.mollie--total-price label,
.form--mazout .gform-body .gfield.mollie--total-price-product label {
  display: none;
}
.form--mazout .gform-body .gfield.mollie--total-price .ginput_container,
.form--mazout .gform-body .gfield.mollie--total-price-product .ginput_container {
  height: 0;
}
.form--mazout .gform-body .gfield.mollie--total-price .ginput_container input,
.form--mazout .gform-body .gfield.mollie--total-price-product .ginput_container input {
  opacity: 0;
  height: 0;
  min-height: 0;
  line-height: 0;
}

#mazout--price-container {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 0rem;
  font-size: 1rem;
}
#mazout--price-container h5 {
  font-weight: 700;
}
#mazout--price-container .mazout--price-element {
  margin: 1rem 0;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
}
#mazout--price-container .mazout--price-element .name {
  width: calc(100% - 140px);
}
#mazout--price-container .mazout--price-element .name .liter {
  color: #BCBCBC;
}
#mazout--price-container .mazout--price-element .price {
  width: 140px;
  text-align: right;
}
#mazout--price-container .mazout--price-element .price.small {
  font-size: 0.9rem;
}
#mazout--price-container .mazout--price-totaalincl {
  border-top: 1px solid #BCBCBC;
}
#mazout--price-container .mazout--price-totaalincl .name {
  color: #056938;
}

.overview--mazout-form {
  max-width: 900px;
  margin: 0 auto;
}

ul {
  margin-left: 20px;
}

.ul--checklist ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ul--checklist ul li {
  padding-left: 2rem;
  position: relative;
}
.ul--checklist ul li:before {
  content: "\f00c";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: #056938;
  font-size: 1rem;
  position: absolute;
  top: 0;
  left: 0;
}

.color_alternate .ul--checklist ul li:before {
  color: #ffffff;
}

.ul--col2 ul {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
}
.ul--col2 ul li {
  width: calc(50% - 1.5rem);
}
@media screen and (max-width: 767px) {
  .ul--col2 ul li {
    width: 100%;
  }
}

.timeline--howto .timeline--item .text--number {
  position: relative;
  z-index: 1;
}
.timeline--howto .timeline--item .text--number .wpb_wrapper {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #ffffff;
  background-color: #323232;
  font-size: 2rem;
  margin: 0 auto;
  margin-bottom: 1rem !important;
}
@media screen and (max-width: 767px) {
  .timeline--howto .timeline--item .text--number .wpb_wrapper {
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
  }
}
.timeline--howto .timeline--item .text--number.active .wpb_wrapper {
  background-color: #ffffff;
  color: #056938;
}
.timeline--howto .timeline--item .text--number:before {
  content: "";
  width: calc(100% - 60px);
  height: 1px;
  background: #ffffff;
  position: absolute;
  left: calc(50% + 60px - 0.5rem);
  top: 30px;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .timeline--howto .timeline--item .text--number:before {
    display: none;
  }
}
.timeline--howto .timeline--item:last-child .text--number:before {
  display: none;
}

.timeline--steps .timeline--item .text--number {
  position: relative;
  z-index: 1;
}
.timeline--steps .timeline--item .text--number .wpb_wrapper {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #ffffff;
  background-color: #056938;
  font-size: 1.25rem;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .timeline--steps .timeline--item .text--number .wpb_wrapper {
    width: 20px;
    height: 20px;
    font-size: 0.9rem;
    margin-top: -3px;
  }
}

.contentbox--app {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1rem;
  border-radius: 0.5rem;
}

.w-filter.layout_ver .w-filter-list .w-filter-item {
  margin-bottom: 2rem;
}
.w-filter.layout_ver .w-filter-list .w-filter-item .w-filter-item-title {
  margin-bottom: 1rem;
}
.w-filter.layout_ver .w-filter-list .w-filter-item .w-filter-item-values .w-filter-item-value input[type=checkbox] {
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
}
.w-filter.layout_ver input:checked ~ .w-filter-item-value-label {
  font-weight: 400;
}

.facetwp-type-proximity {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  max-width: 700px;
  margin: 0 auto;
  z-index: 10;
  position: relative;
}
@media screen and (max-width: 767px) {
  .facetwp-type-proximity {
    flex-wrap: wrap;
  }
}
.facetwp-type-proximity .facetwp-radius {
  max-width: 120px;
}
.facetwp-type-proximity .facetwp-input-wrap {
  width: 100%;
}

.pagination.navigation {
  margin-top: 3rem;
}
.pagination.navigation .page-numbers {
  font-size: 1rem;
  line-height: 2rem;
  height: 2rem;
  width: 2rem;
  overflow: hidden;
  border-radius: 50%;
  transition: none;
}

.table--openinghours table {
  width: 100%;
}
.table--openinghours table tr:nth-child(odd) {
  background-color: #056938;
  color: #ffffff;
}
.table--openinghours table tr td {
  padding: 1rem;
  border: none;
}
.table--openinghours table tr td:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.table--openinghours table tr td:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  text-align: right;
}

#sitemap_authors,
#sitemap_beoordelingen,
#sitemap_headers,
#sitemap_pagetemplates,
#sitemap_reusableblocks,
#sitemap_gridweergaves,
#sitemap_veelgesteldevragen,
#sitemap_shopsl\'unique,
#sitemap_testimonials,
#sitemap_gridlayouts,
#sitemap_témoignages,
#sitemap_entêtes,
#sitemap_modèlesdepages,
#sitemap_blocsréutilisables,
#sitemap_grilles {
  display: none;
}

body:not(.page--alike) .alike-compare-widget-wrapper,
body:not(.page--alike) .alike-compare-area {
  display: none !important;
}

body.page--alike .alike-compare-widget-wrapper .alike-compare-widget-button {
  background-color: #e2251d !important;
  color: #ffffff !important;
  width: auto !important;
  padding: 10px 10px 8px 10px !important;
  white-space: nowrap !important;
  height: auto !important;
}
body.page--alike .alike-compare-widget-wrapper .alike-compare-widget-button .alike-compare-widget-button-text {
  padding: 0 !important;
}
body.page--alike .alike-compare-widget-wrapper .alike-compare-widget-button .alike-compare-widget-counter {
  position: relative !important;
  top: -2px !important;
  color: #151515 !important;
  margin-left: 5px !important;
}
body.page--alike .alike-compare-widget-wrapper .alike-compare-widget-content .alike-compare-widget-post-title {
  display: none !important;
}
body.page--alike .alike-compare-widget-wrapper .alike-compare-widget-content .alike-compare-widget-post-remove-button {
  color: #056938 !important;
}
body.page--alike .rq-alike-compare {
  border: none !important;
}
body.page--alike .rq-alike-compare .rq-alike-show-difference {
  display: none !important;
}
body.page--alike .rq-alike-compare .wp-block-alike-block-compare-table {
  width: 100% !important;
}
body.page--alike .rq-alike-compare .wp-block-alike-block-compare-table table td:first-child {
  width: 150px !important;
  min-width: auto !important;
}
body.page--alike .rq-alike-compare .wp-block-alike-block-compare-table table td:nth-child(2),
body.page--alike .rq-alike-compare .wp-block-alike-block-compare-table table td:nth-child(3),
body.page--alike .rq-alike-compare .wp-block-alike-block-compare-table table td:nth-child(4) {
  width: 150px !important;
  min-width: auto !important;
}
body.page--alike .rq-alike-compare .wp-block-alike-block-compare-table table td {
  white-space: unset !important;
}

.grid--compare article .post_image {
  width: 100% !important;
  height: 0 !important;
  padding-bottom: 100% !important;
  position: relative !important;
}
.grid--compare article .post_image img {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
}
.grid--compare article .w-html {
  text-align: center !important;
}
.grid--compare article .w-html .grid--compare-button {
  font-size: 12px !important;
  line-height: 1 !important;
  font-weight: 400 !important;
  font-style: normal !important;
  text-transform: none !important;
  letter-spacing: 0em !important;
  border-radius: 0.5rem !important;
  padding: 0.5em 1em !important;
  background: #056938 !important;
  border-color: transparent !important;
  color: #ffffff !important;
}

.blog--featured article .post_title {
  display: block !important;
}
.blog--featured article .w-btn {
  text-align: left !important;
}
@media screen and (max-width: 767px) {
  .blog--featured article .post_image > div {
    padding-bottom: 55% !important;
  }
}

.blog--aside article .post_title {
  display: block !important;
}
.blog--aside article .w-btn {
  text-align: left !important;
}
@media screen and (max-width: 767px) {
  .blog--aside article .post_image > div {
    padding-bottom: 55% !important;
  }
}

.grid--shops article .w-grid-item-h {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  padding: 1rem;
}
.grid--shops .w-grid-list {
  display: block;
}
.grid--shops .w-grid-list .facetwp-template {
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--gap, 0px) * -1);
  width: 100%;
}
.grid--shops .w-grid-list .facetwp-template > * {
  width: 33.333%;
}

.grid--carwashes article .w-grid-item-h {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  padding: 1rem;
  position: relative;
}
.grid--carwashes article .w-grid-item-h a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}
.grid--carwashes article .w-grid-item-h .adres > *, .grid--carwashes article .w-grid-item-h .openingsuren > * {
  display: block;
}
.grid--carwashes .w-grid-list {
  display: block;
}
.grid--carwashes .w-grid-list .facetwp-template {
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--gap, 0px) * -1);
  width: 100%;
}
.grid--carwashes .w-grid-list .facetwp-template > * {
  width: 33.333%;
}

.grid--vacatures article {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 0.5rem;
}
.grid--vacatures .pagination .nav-links {
  gap: 1rem;
}
.grid--vacatures .pagination .page-numbers {
  display: none;
}
.grid--vacatures .pagination .page-numbers.next, .grid--vacatures .pagination .page-numbers.prev {
  display: block;
  width: auto !important;
  height: auto !important;
}
.grid--vacatures .pagination .page-numbers.next:hover, .grid--vacatures .pagination .page-numbers.prev:hover {
  background: transparent !important;
}
.grid--vacatures .pagination .page-numbers.next span, .grid--vacatures .pagination .page-numbers.prev span {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.grid--vacatures .pagination .page-numbers.next:after, .grid--vacatures .pagination .page-numbers.prev:after {
  font-size: 1rem;
}
.grid--vacatures .pagination .page-numbers.prev {
  display: flex;
  flex-direction: row-reverse;
}
.grid--vacatures .pagination .page-numbers.prev span {
  margin-right: 0rem;
  margin-left: 0.5rem;
}

.faq--items article {
  padding: 1rem 0;
  border-bottom: 1px solid #000000;
  display: block;
}
.faq--items article .faq--title {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5;
  cursor: pointer;
  display: block;
  padding-right: 2rem;
  position: relative;
}
.faq--items article .faq--title:after {
  content: "\f067";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 300;
  text-decoration: inherit;
  color: #056938;
  font-size: 1.5rem;
  position: absolute;
  top: -0.25rem;
  right: 0;
  transition: all 0.3s;
}
.faq--items article.active .faq--title:after {
  transform: rotate(45deg);
}
.faq--items article .faq--content {
  display: none;
  padding: 1.5rem 0 1rem 0;
}
.faq--items article .faq--content a {
  text-decoration: none;
  border-bottom: 1px solid;
}

.color_alternate .faq--items article {
  border-bottom: 1px solid #ffffff;
}
.color_alternate .faq--items article .faq--title:after {
  color: #ffffff;
}

.grid--faq-taxonomy .post_title {
  display: block;
}
.grid--faq-taxonomy .post_title a {
  display: block;
  background-color: #A3A3A3;
  color: #ffffff;
  width: 100%;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  text-align: right;
}
@media screen and (max-width: 1024px) {
  .grid--faq-taxonomy .post_title a {
    padding: 1rem;
  }
}
.grid--faq-taxonomy .post_title a:hover, .grid--faq-taxonomy .post_title a.active {
  background-color: #056938;
}
@media screen and (max-width: 767px) {
  .grid--faq-taxonomy .w-grid-item.current .post_title a {
    position: relative;
    padding-right: 2rem !important;
    text-align: left !important;
  }
  .grid--faq-taxonomy .w-grid-item.current .post_title a:after {
    content: "\f078";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 300;
    text-decoration: inherit;
    color: inherit;
    font-size: 1rem;
    position: absolute;
    top: calc(50% - 0.6rem);
    right: 1rem;
  }
  .grid--faq-taxonomy .w-grid-list {
    display: none;
  }
}