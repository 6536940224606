.timeline--howto{
    .timeline--item{
        .text--number{
            position: relative;
            z-index: 1;
        
            .wpb_wrapper{
                width: 60px; 
                height: 60px; 
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                color: $white;
                background-color: $grey;
                font-size: 2rem; 
                margin: 0 auto;
                margin-bottom: 1rem !important;

                @media screen and (max-width: 767px){
                    width: 40px; 
                    height: 40px; 
                    font-size: 1.5rem; 
                }
            }
        
            &.active{
                .wpb_wrapper{
                    background-color: $white;
                    color: $color__maes__green;
                }
            }
        
            &:before {
                content: '';
                width: calc(100% - 60px);
                height: 1px;
                background: $white;
                position: absolute;
                left: calc(50% + 60px - .5rem);
                top: 30px;
                z-index: -1;

                @media screen and (max-width: 767px){
                    display: none;
                }
            }
        }
        
        &:last-child{
            .text--number{
                &:before {
                    display: none;
                }
            }
        }
    }
}

.timeline--steps{
    .timeline--item{
        .text--number{
            position: relative;
            z-index: 1;
        
            .wpb_wrapper{
                width: 30px; 
                height: 30px; 
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                color: $white;
                background-color: $color__maes__green;
                font-size: 1.25rem; 
                margin: 0 auto;

                @media screen and (max-width: 767px){
                    width: 20px;
                    height: 20px;
                    font-size: 0.9rem;
                    margin-top: -3px;
                }
            }
        }
    }
}
