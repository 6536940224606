.menu--view{
    background: $white;
    border-radius: 0.5rem;
    padding: 1rem;

    *{
        color: $color__maes__green !important;
    }

    ul{
        list-style: none; 
        margin: 0; 
        padding: 0;
        display: flex; 
        flex-wrap: wrap;
        gap: 0 1rem;

        li{
            background-color: $white;
            width: calc(50% - 0.5rem);
            margin: 0;

            @media screen and (max-width: 600px){
                width: 100%;
            }

            @media screen and (min-width: 601px){
                &:nth-child(4n + 1), &:nth-child(4n + 2){
                    background-color: $color__maes__green_lightbg;
                }
            }
            @media screen and (max-width: 600px){
                &:nth-child(odd){
                    background-color: $color__maes__green_lightbg;
                }
            }

            &:hover{
                background-color: $color__maes__green_lightlightbg;
            }
            

            a{
                padding: 7px 15px;
                width: 100%;
                display: block;
                position: relative; 
                padding-right: calc(0.8rem + 15px);

                &:after{
                    content: "\f054";
                    font-family: FontAwesome;
                    font-style: normal;
                    font-weight: normal;
                    text-decoration: inherit;
                    color: inherit;
                    font-size: 0.75rem;
                    position: absolute;
                    top: 50%; 
                    right: 15px;
                    transform: translateY(-50%);
                }
            }
        }
    }

    &-black{
        background: transparent; 
        border-radius: 0;
        padding: 0;

        *{
            color: $white !important;
        }

        ul{
            li{
                width: 100% !important;
                background: $black !important; 
                border-radius: 0.5rem; 
                padding: 0.1rem 0.5rem; 
                margin: 0.1rem 0;
                color: $white !important;

                &:hover{
                    background: $grey !important;
                }

                a{
                    text-align: left;
                    border: none !important;
                }
            }
        }
    }
}