header{
    background-color: $white;
    background-image: url('../images/header-bg.svg'); 
    background-size: auto 80%; 
    background-position: center bottom; 
    background-repeat: repeat-x;

    .l-subheader{
        background: transparent !important;
    }

    .l-subheader.at_top{
        ul{
            li{
                a:not(.w-btn){
                    &:hover{
                        text-decoration: underline;
                    }
                }
                &:hover{
                    a:not(.w-btn){
                        color: inherit !important;
                    }
                }
            }
        }
    }

    nav{
        .w-nav-icon{
            color: $color__maes__green;
        }
        ul{
            li{
                & > .w-btn{
                    margin-right: 0px !important;
                }

                .w-nav-arrow{
                    font-size: 0.7rem;
                }
            }
            
            &.level_1{
                ul{
                    li{
                        a{
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1024px){
        .source_wpml{
            .w-dropdown-h{
                display: flex; 
                gap: 1rem;

                .w-dropdown-current{
                    .w-dropdown-item{
                        .w-dropdown-item-title{
                            text-decoration: underline;
                        }
                    }
                }

                .w-dropdown-item{
                    padding: 0 !important; 

                    &:after{
                        display: none;
                    }

                    .w-dropdown-item-title{
                        font-size: 14px; 
                        line-height: 14px; 
                        color: $color__maes__green;
                    }
                }
                .w-dropdown-list{
                    transform: none !important;
                    opacity: 1 !important;
                    position: relative !important;
                    background: transparent !important;
                    transition: none !important;
                    padding: 0 !important;
                    margin: 0 !important;
                    box-shadow: none !important; 
                    border: none !important; 
                    top: 0 !important; 
                    left: 0 !important;

                    .w-dropdown-list-h{
                        display: flex;
                        gap: 1rem;
                    }
                }
            }
            
        }
    }   
}