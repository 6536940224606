/* GASSTATION FINDER */
.form--gasstation-finder{
    position: relative; 

    input[type="text"]{
        padding-right: 155px;
        min-height: 50px;
        line-height: 50px;
    }

    input[type="submit"]{
        position: absolute; 
        top: 4px; 
        right: 5px;
        min-width: 140px;
    }

    .suggestion-box {
        border: 1px solid $mediumgrey;
        max-height: 150px;
        overflow-y: auto;
        position: absolute;
        background-color: $white;
        width: calc(100% - 2px); /* Adjust width according to input field */
        z-index: 1000;
        display: none;


        .suggestion-item {
            padding: 10px;
            cursor: pointer;

            &:hover {
                background-color: $color__maes__green_lightbg;
            }
        }
    }
}


/* NEWSLETTER - FOOTER */
.newsletter-footer{
    position: relative; 

    .emailaddress{
        input#input_8_1, input#input_14_1, input#input_11_1{
            padding-right: 155px !important;
            min-height: 50px;
        }
    }

    input[type="submit"]{
        position: absolute; 
        top: 4px; 
        right: 5px;
        min-width: 140px;
    }

    .privacy-policy{
        font-size: 14px;
        
        a{
            text-decoration: underline;

            &:hover{
                font-weight: 500;
            }
        }
    }
}
.gform_wrapper form.newsletter-footer .gform_body ul.gform_fields {
    .gfield{
        padding: 0 !important;
        margin-bottom: 0.5rem !important;

        .ginput_container{
            label{
                display: inline-block !important;
                font-weight: 400;
            }
        }
    }
}


/* GRAVITY FORMS */
.color_alternate, footer{
    .gform_confirmation_message{
        color: $white;
    }
}
.form--box-container{
    // .gform_body{
    form{
        background-color: $white; 
        padding: 3rem; 
        border-radius: 2rem; 
        box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);

        @media screen and (max-width: 767px){
            padding: 3rem 0.5rem; 
        }
    }
}

.gform_wrapper {
    .validation_error {
        display: none;
    }

    .gf_progressbar{
        box-shadow: none;
        border: 1px solid $mediumgrey;
        margin-bottom: 1.5rem;
        height: 1.5rem; 

        .gf_progressbar_percentage{
            padding: 0.25rem 0.5rem;
        }
    }

    form {
        .gform_body {
            ul.gform_fields {
                list-style-type: none;
                margin: 0; 
                padding: 0;
                display: flex; 
                flex-wrap: wrap;

                .hidden_label, .hide-label {
                    & > label{
                        display: none !important;
                    }
                }

                .gfield_visibility_ {
                    display: none;
                }

                .gfield {
                    width: 100%;
                    margin-bottom: 2rem !important;
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                    position: relative;
                    max-width: 100%;

                    &.half {
                        width: 50%;
            
                        @media (max-width: 767px) {
                          width: 100%;
                        }
                    }

                    &.gform_hidden{
                        margin-bottom: 0 !important;
                    }

                    label{
                        margin-bottom: 0.5rem; 
                        display: block;
                        font-weight: 500;
                    }

                    .gfield_description{
                        display: block; 
                        margin-top: 0.5rem;
                    }

                    .instruction {
                        display: none;
                    }

                    .ginput_counter {
                        display: none;
                    }

                    .gfield_radio,
                    .gfield_checkbox {
                        margin: 0;
                        padding: 0;
                        list-style: none;

                        label{
                            font-weight: 400;
                        }

                        li {
                            padding: 9px 0 0;

                            &:first-child {
                                padding-top: 0;
                            }
                        }
                    }

                    .gfield_radio{
                        // display: flex;
                        // align-items: stretch;
                        // justify-content: flex-start;
                        margin-left: -15px;
                        margin-right: -15px;
                        // flex-wrap: wrap;

                        li {
                            flex: 0 0 225px;
                            padding: 3px 15px;

                            label{
                                display: inline;
                            }
                        }
                    }

                    &.gf_readonly {
                        input {
                            background: $color__maes__green_lightbg;
                            pointer-events: none;
                        }
                    }

                    .validation_message{
                        font-size: 14px; 
                        color: $color__maes__red;
                        background: $white !important;
                        padding: 0 5px !important; 
                        border-radius: 5px;
                        display: inline-block;
                    }

                    ul.gfield_checkbox {
                        input {
                            display: none;
                        }

                        input + label {
                            position: relative;
                            padding-left: 40px;
                            display: inline-block;

                            &:before {
                            content: "";
                                width: 24px;
                                height: 24px;
                                background-color: $white;
                                border-radius: 2px;
                                border: 1px solid $color__maes__border_grey;
                                position: absolute;
                                left: 0px;
                                top: 50%;
                                transform: translateY(-50%);
                            }

                            a {
                                display: inline-table; 
                            }
                        }
                        input:checked + label {
                            &:before {
                                border: 1px solid $color__maes__green;
                            }

                            &:after {
                            content: "";
                                position: absolute;
                                left: 7px;
                                background: none;
                                width: 10px;
                                height: 15px;
                                border: 2px solid $color__maes__green;
                                border-radius: 0;
                                border-top: none;
                                border-left: none;
                                top: 50%;
                                -webkit-transform: translateY(-50%) rotate(40deg);
                                transform: translateY(-10px) rotate(40deg);
                            }
                        }
                    }

                    .gform-field-label{
                        a{
                            text-decoration: underline;
                            border: none !important;

                            &:hover{
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }

        .gf_page_steps {
            display: flex;
            margin-bottom: 2rem;
            justify-content: space-between;

            .gf_step {
                padding: 0 1rem;
                position: relative;
                font-size: 1rem;
                line-height: 1.5;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                z-index: 1;
                flex: 1 1 0px;

                @media screen and (max-width: 767px){
                    display: none !important;
                    text-align: center;
    
                    &.gf_step_active{
                        display: block !important;
                    }
                }

                .gf_step_number {
                    width: 50px; 
                    height: 50px; 
                    display: flex; 
                    justify-content: center; 
                    align-items: center;
                    border-radius: 50%;
                    background-color: $lightgrey;
                    color: $color__text-input-focus; 
                    font-size: 1.5rem;
                    margin-bottom: 1rem;

                    @media screen and (max-width: 767px){
                        margin-left: auto; 
                        margin-right: auto;
                    }
                }

                &.gf_step_active {
                    .gf_step_number {
                        background-color: $color__maes__green;
                        color: $white;
                    }
                }

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 25px;
                    width: calc(50% - 35px);
                    height: 1px;
                    background: $color__maes__border_grey;
                    z-index: -1;

                    @media screen and (max-width: 767px){
                        display: none;
                    }
                }

                &:first-child:before{
                    display: none;
                }

                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 25px;
                    width: calc(50% - 35px);
                    height: 1px;
                    background: $color__maes__border_grey;
                    z-index: -1;

                    @media screen and (max-width: 767px){
                        display: none;
                    }
                }

                &:last-child:after{
                    display: none;
                }

            }
        }

        .review-table-holder {
            .review-line {
                display: flex;
                border-bottom: 1px solid #dddddd;
                
                @media screen and (max-width: 600px) {
                    flex-wrap: wrap;
                }

                span {
                    display: block;
                    font-size: 15px;
                    line-height: 44px;
                }
                span.title {
                    font-weight: 500;
                    flex: 1 0 40%;

                    @media screen and (max-width: 600px) {
                        flex: 1 0 100%;
                        line-height: 1.2em;
                        margin-bottom: 10px;
                        margin-top: 10px;
                    }
                }
                span.value {
                    flex: 1 0 60%;

                    @media screen and (max-width: 600px) {
                        flex: 1 0 100%;
                        line-height: 1.2em;
                        margin-bottom: 10px;
                    }
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .gform_page_footer, .gform_footer{
            text-align: left;

            input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]):not([type=submit]){
                line-height: 1.2 !important;
                min-height: 40px !important;
                padding: 0.7em 1em;
            }
        }

        .gform_previous_button, 
        .gform_next_button{
            font-size: 1rem;
            line-height: 1.2 !important;
            font-weight: 400;
            font-style: normal;
            text-transform: none;
            letter-spacing: 0em;
            border-radius: 0.5rem;
            padding: 0.7em 1em;
            border: none; 
            min-height: 0;
        }
    }
}


/* MAZOUT FORM */
.form--mazout-container{
    .gform_heading{
        .gform_required_legend{
            display: none;
        }
    }
    .gform_validation_errors{
        display: none;
    }
}
.form--mazout{
    position: relative; 

    .gf_page_steps {
        border: none !important;

        .gf_step{
            margin: 0 !important;

            .gf_step_number {
                width: 20px !important;
                height: 20px !important;
                background-color: #323232 !important;
                color: #ffffff !important;
                font-size: 12px !important;
                margin-bottom: 0 !important;
                line-height: 1 !important;
                position: absolute !important;
                bottom: 0 !important;
                left: calc(50% - 10px) !important;
                border: 1px solid #323232 !important;

                &:before, &:after{
                    display: none;
                }
            }

            .gf_step_label{
                margin-bottom: 25px !important;
                font-weight: 400;
                color: #056938;
            }

            &.gf_step_active{
                .gf_step_number {
                    background-color: #ffffff !important;
                    color: #056938 !important;
                    border: 1px solid #056938 !important;
                }
            }

            &:after {
                top: auto !important;
                bottom: 10px !important;
                width: calc(50% - 20px) !important;
                background: #C1C1C1 !important;
            }
            &:before {
                top: auto !important;
                bottom: 10px !important;
                width: calc(50% - 20px) !important;
                background: #C1C1C1 !important;
            }
        }
    }

    .gform_page {
        margin-top: 4rem; 

        .gform_page_fields{
            padding: 2.5rem 2.5rem 1rem 2.5rem; 
            box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.4);
            border-radius: 10px;
        }
    }

    .gform_page_footer{
        input[type="button"]{
            border-radius: 3rem !important;
            font-weight: 700 !important;
            font-size: 1rem !important;
            padding: 0.7rem 1.5rem !important;
        }
    }

    .gform-body{
        .gfield{
            margin-bottom: 1rem !important;
        }
    }

    h5{
        font-weight: 700 !important;
    }

    .gfield_label{
        font-weight: 400 !important;
    }

    .gfield_error{
        legend, label{
            color: #1a1a1a !important;
        }
        .gfield_description{
            font-size: 13px !important
        }
    }

    .mazout--product{
        .gfield_radio{
            display: flex;
            flex-direction: row;
            gap: 1.5rem;

            .gchoice{
                position: relative;

                label{
                    background-color: #ffffff;
                    color: #056938 !important; 
                    box-shadow: 0px 4px 4px 0px #00000040;
                    border-radius: 10px;
                    padding: 0.7rem 1rem;
                    white-space: nowrap;
                    max-width: none;
                    font-weight: 700;
                    font-size: 1.15rem;
                }
                input{
                    z-index: -1;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                input[type=radio]:checked + label {
                    background-color: #056938; 
                    color: #ffffff !important;
                }
            }
        }
    }

    .zip-input{
        display: none;
    }

    #suggestions-container{
        position: relative;
        margin-top: -1rem;

        .suggestion-box {
            border: 1px solid $mediumgrey;
            max-height: 150px;
            overflow-y: auto;
            position: absolute;
            background-color: $white;
            width: calc(100% - 2px); /* Adjust width according to input field */
            z-index: 1000;
            display: none;
    
    
            .suggestion-item {
                padding: 10px;
                cursor: pointer;
    
                &:hover {
                    background-color: $color__maes__green_lightbg;
                }
            }
        }
    }

    .mazout--betaling{
        .gfield_radio{
            display: flex;
            flex-direction: row;
            gap: 1.5rem;
            flex-wrap: wrap;
            
            .gchoice{
                position: relative;

                label{
                    background-color: #ffffff;
                    color: #393939 !important; 
                    box-shadow: 0px 4px 4px 0px #00000040;
                    border-radius: 10px;
                    padding: 0.7rem 1rem;
                    white-space: nowrap;
                    max-width: none;
                    font-weight: 700;
                    font-size: 1.15rem;
                }
                input{
                    z-index: -1;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                input[type=radio]:checked + label {
                    background-color: #056938; 
                    color: #ffffff !important;
                }
            }
        }
    }

    .clientnumber--aware{
        margin-top: 2rem !important;
        
        .validation_message{
            display: none !important;
        }
    }
    .gform-body .gfield.mollie--total-price, 
    .gform-body .gfield.mollie--total-price-product {
        opacity: 0;
        height: 0;
        margin-bottom: 0 !important;

        label {
            display: none;
        }
        .ginput_container{
            height: 0;

            input{
                opacity: 0;
                height: 0;
                min-height: 0; 
                line-height: 0;
            }
        }
    }
}
#mazout--price-container{
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 1rem; 
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    font-size: 1rem;

    h5{
        font-weight: 700;
    }

    .mazout--price-element{
        margin: 1rem 0;
        display: flex; 
        gap: 0.5rem;
        justify-content: space-between;

        .name{
            width: calc(100% - 140px);

            .liter{
                color: #BCBCBC;
            }
        }
        .price{
            width: 140px;
            text-align: right;

            &.small{
                font-size: 0.9rem;
            }
        }
    }
    .mazout--price-totaalincl{
        border-top: 1px solid #BCBCBC;

        .name{
            color: #056938;
        }
    }
}
.overview--mazout-form{
    max-width: 900px; 
    margin: 0 auto;
}