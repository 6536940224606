.w-btn{
    &.us-btn-style_11,
    &.us-btn-style_12,
    &.us-btn-style_13{
        display: block;

        &:hover{
            .w-btn-label{
                border-bottom: 1px solid;
            }
        }
    }

    &.us-btn-style_14{ // QUESTION MARK GREEN
        display: flex; 
        justify-content: space-between; 
        align-items: center;
        
        .w-btn-label{
            display: flex; 
            justify-content: center; 
            align-items: center;
            margin: 0; 
            padding: 0;

            &:before{
                content: ''; 
                width: 20px; 
                min-width: 20px; 
                height: 20px; 
                margin-right: 0.8rem;
                background-image: url('../images/question-mark.svg'); 
                background-size: contain; 
                background-position: center; 
                background-repeat: no-repeat;
            }
        }

        i{
            font-size: 0.8rem;
        }
    }

    &.us-btn-style_15{ // QUESTION MARK WHITE
        display: flex; 
        justify-content: space-between; 
        align-items: center;
        
        .w-btn-label{
            display: flex; 
            justify-content: center; 
            align-items: center;
            margin: 0; 
            padding: 0;

            &:before{
                content: ''; 
                width: 20px; 
                min-width: 20px; 
                height: 20px; 
                margin-right: 0.8rem;
                background-image: url('../images/question-mark-green.svg'); 
                background-size: contain; 
                background-position: center; 
                background-repeat: no-repeat;
            }
        }

        i{
            font-size: 0.8rem;
        }
    }

    // &.icon_atright{
    //     .fa-arrow-right{
    //         transform: translateX(0);
    //         transition: all 0.3s;
    //     }

    //     &:hover{
    //         .fa-arrow-right{
    //             transform: translateX(3px);
    //         }
    //     }
    // }
}

#blog--categories{
    .active{
        .w-btn{
            background-color: #333333;
        }
    }
}
