.w-filter{
    &.layout_ver{
        .w-filter-list{
            .w-filter-item{
                margin-bottom: 2rem;
                .w-filter-item-title{
                    margin-bottom: 1rem;
                }
                .w-filter-item-values{
                    .w-filter-item-value{
                        input[type="checkbox"]{
                            width: 1rem; 
                            height: 1rem; 
                            line-height: 1rem;
                        }
                    }
                }
            }
        }

        input:checked~.w-filter-item-value-label {
            font-weight: 400;
        }
    }
}