footer{
    .wpb_text_column{
        .wpb_wrapper{
            p{
                a{
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    #footer--sub{
        .wrapper{
            gap: 1.5rem;
        }
    }

    .w-iconbox-icon {
        margin-right: 0.3rem !important;
    }

    #footer-menu{
        @media screen and (max-width: 1024px) and (min-width: 768px){
            display: flex; 
            flex-wrap: wrap;

            .vc_column_container{
                width: calc(25% - 3rem);
            }
            
            .vc_column_container:nth-child(3){
                display: none;
            }
        }
        @media screen and (max-width: 767px){
            display: flex; 
            flex-wrap: wrap;

            .vc_column_container{
                width: calc(50% - 3rem);
            }
        }
    }

    .newsletter-footer{
        position: relative; 

        .emailaddress{
            .ginput_container{
                input#input_8_1, input#input_14_1, input#input_11_1{
                    padding-right: 115px !important;
                    padding-left: 10px !important;
                    font-size: 14px !important;
                    line-height: 40px !important;
                    min-height: 45px !important;
                }
            }
        }

        input[type="submit"]{
            font-size: 14px !important;
            min-width: 100px !important;
        }

        .privacy-policy{
            font-size: 12px !important;
        }
    }
}