.g-breadcrumbs{
    font-size: 14px; 

    .g-breadcrumbs-separator{
        font-size: 12px; 
        margin-top: 2px;
    }

    .g-breadcrumbs-item:not(:last-child){
        text-decoration: underline;
    }

    .w-btn-wrapper{
        margin: 0.1rem 0 !important;

        .w-btn{
            font-size: 14px !important;

            .w-btn-label{
                text-decoration: underline;
            }

            i{
                font-size: 12px !important;
                margin-top: 2px;
                margin: 0 0.6em;
            }
        }
    }

    a:hover{
        .w-btn-label{
            border: none !important;
            color: $color__maes__green !important; 
        }
    }
}