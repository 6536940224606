.grid--vacatures{
    article{
        background-color: #ffffff; 
        padding: 2rem; 
        border-radius: 0.5rem;
    }

    .pagination{
        .nav-links{
            gap: 1rem; 
        }
        .page-numbers{
            display: none; 

            &.next, &.prev{
                display: block;
                width: auto !important;
                height: auto !important;

                &:hover{
                    background: transparent !important;
                }

                span{
                    font-size: 1rem; 
                    margin-right: 0.5rem;
                }
                
                &:after{
                    font-size: 1rem;
                }
            }
            &.prev{
                display: flex;
                flex-direction: row-reverse;

                span{
                    margin-right: 0rem;
                    margin-left: 0.5rem;
                }
            }
        }
    }
}