body:not(.page--alike){
    .alike-compare-widget-wrapper, 
    .alike-compare-area{
        display: none !important;
    }
}

body.page--alike{
    .alike-compare-widget-wrapper{
        .alike-compare-widget-button{
            background-color: $color__maes__red !important;
            color: $white !important;
            width: auto !important;
            padding: 10px 10px 8px 10px !important;
            white-space: nowrap !important;
            height: auto !important;

            .alike-compare-widget-button-text{
                padding: 0 !important;
            }
            
            .alike-compare-widget-counter{
                position: relative !important;
                top: -2px !important;
                color: $color__text-input-focus !important;
                margin-left: 5px !important;
            }
        }

        .alike-compare-widget-content{
            .alike-compare-widget-post-title{
                display: none !important;
            }
            .alike-compare-widget-post-remove-button{
                color: $color__maes__green !important;
            }
        }
    }

    .rq-alike-compare{
        border: none !important; 

        .rq-alike-show-difference{
            display:none !important;
        }

        .wp-block-alike-block-compare-table{
            width: 100% !important; 
            
            table{
                td:first-child{
                    width: 150px !important; 
                    min-width: auto !important;
                }
                td:nth-child(2), 
                td:nth-child(3), 
                td:nth-child(4){
                    width: 150px !important; 
                    min-width: auto !important;
                }
                td{
                    white-space: unset !important;
                }
            }
        }
    }
}

.grid--compare{
    article{
        .post_image{
            width: 100% !important; 
            height: 0 !important; 
            padding-bottom: 100% !important;
            position: relative !important;
            
            img{
                position: absolute !important;
                left: 0 !important; 
                top: 0 !important; 
                width: 100% !important; 
                height: 100% !important; 
                object-fit: contain !important;
            }
        }
    
        .w-html{
            text-align: center !important;

            .grid--compare-button{
                font-size: 12px !important;
                line-height: 1 !important;
                font-weight: 400 !important;
                font-style: normal !important;
                text-transform: none !important;
                letter-spacing: 0em !important;
                border-radius: 0.5rem !important;
                padding: 0.5em 1em !important;
                background: $color__maes__green !important;
                border-color: transparent !important;
                color: $white !important;
            }
        }
    }
}