.faq--items{
    article{
        padding: 1rem 0;
        border-bottom: 1px solid $black; 
        display: block;

        .faq--title{
            font-size: 1.125rem; 
            font-weight: 600; 
            line-height: 1.5;
            cursor: pointer; 
            display: block; 
            padding-right: 2rem; 
            position: relative; 

            &:after{
                content: "\f067";
                font-family: FontAwesome;
                font-style: normal;
                font-weight: 300;
                text-decoration: inherit;
                color: $color__maes__green;
                font-size: 1.5rem;
                position: absolute;
                top: -0.25rem;
                right: 0;
                transition: all 0.3s;
            }
        }

        &.active{
            .faq--title{
                &:after{
                    transform: rotate(45deg);
                }
            }
        }

        .faq--content{
            display: none;
            padding: 1.5rem 0 1rem 0;

            a{
                text-decoration: none;
                border-bottom: 1px solid;
            }
        }
    }
}

.color_alternate{
    .faq--items{
        article{
            border-bottom: 1px solid $white; 

            .faq--title{
                &:after{
                    color: $white;
                }
            }
        }
    }
}

.grid--faq-taxonomy{
    .post_title{
        display: block;

        a{
            display: block;
            background-color: $mediumgrey; 
            color: $white; 
            width: 100%; 
            padding: 1rem 2rem; 
            border-radius: 0.5rem;
            text-align: right;

            @media screen and (max-width: 1024px){
                padding: 1rem; 
            }
            
            &:hover, &.active{
                background-color: $color__maes__green;
            }
        }
    }

    @media screen and (max-width: 767px){
        .w-grid-item.current{
            .post_title{
                a{
                    position: relative; 
                    padding-right: 2rem !important;
                    text-align: left !important;
        
                    &:after{
                        content: "\f078";
                        font-family: FontAwesome;
                        font-style: normal;
                        font-weight: 300;
                        text-decoration: inherit;
                        color: inherit;
                        font-size: 1rem;
                        position: absolute;
                        top: calc(50% - 0.6rem);
                        right: 1rem;
                    }
                }
            }
        }
        .w-grid-list{
            display: none;
        }
    }
}