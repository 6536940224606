#sitemap_authors, 
#sitemap_beoordelingen, 
#sitemap_headers, 
#sitemap_pagetemplates, 
#sitemap_reusableblocks, 
#sitemap_gridweergaves, 
#sitemap_veelgesteldevragen, 
#sitemap_shopsl\'unique, 
#sitemap_testimonials, 
#sitemap_gridlayouts, 
#sitemap_témoignages, 
#sitemap_entêtes, 
#sitemap_modèlesdepages, 
#sitemap_blocsréutilisables, 
#sitemap_grilles
{
    display: none;
}