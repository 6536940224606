.section--bg-wave{
    .l-section-img{
        background-position: center calc(100% + 80px) !important;
        background-repeat: no-repeat !important;
        background-size: 2800px !important;
    }

    &.bg--higher{
        .l-section-img{
            background-position: center calc(100% + 50px) !important;

            @media screen and (max-width: 767px){
                background-position: center calc(100% + 70px) !important;
            }
        }
    }
}