.table--openinghours{
    table{
        width: 100%; 

        tr{
            &:nth-child(odd){
                background-color: $color__maes__green; 
                color: $white;
            }

            td{
                padding: 1rem; 
                border: none;

                &:first-child{
                    border-top-left-radius: 0.5rem;
                    border-bottom-left-radius: 0.5rem;
                }
                &:last-child{
                    border-top-right-radius: 0.5rem;
                    border-bottom-right-radius: 0.5rem;
                    text-align: right;
                }
            }
        }
    }
}