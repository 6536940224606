.facetwp-type-proximity{
    display: flex; 
    justify-content: center; 
    align-items: center;
    gap: 0.5rem;
    max-width: 700px;
    margin: 0 auto;
    z-index: 10;
    position: relative;

    @media screen and (max-width: 767px){
        flex-wrap: wrap;
    }

    .facetwp-radius{
        max-width: 120px;
    }

    .facetwp-input-wrap{
        width: 100%;
    }
}