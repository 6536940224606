.grid--carwashes{
    article{
        .w-grid-item-h{
            box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
            border-radius: 0.5rem; 
            padding: 1rem;
            position: relative; 

            a{
                position: absolute; 
                left: 0; 
                top: 0; 
                width: 100%; 
                height: 100%; 
                z-index: 2;
                cursor: pointer;
            }

            .adres, .openingsuren{
                > *{
                    display: block;
                }
            }
        }
    }
    .w-grid-list{
        display: block;

        .facetwp-template{
            display: flex;
            flex-wrap: wrap;
            margin: calc(var(--gap, 0px)* -1);
            width: 100%;

            & > * {
                width: 33.333%;
            }
        }
    }
}