.owl-carousel{
    padding-left: 60px !important; 
    padding-right: 60px !important; 

    .owl-nav{
        &:not(.disabled){
            display: block !important;
        }

        button{
            border-width: 0px !important;
            width: 40px !important;
            height: 40px !important;
            background: $color__maes__grey_light !important;
            border-radius: 50% !important;

            &.owl-prev{
                left: 0 !important; 
                right: auto !important;
            }

            &.owl-next{
                right: 0 !important; 
                left: auto !important;
            }
        }
    }   
}

.carousel--logos{
    img{
        max-height: 65px;
        width: auto !important;
        margin: 0 auto;
        max-width: 100%;
        display: block;
    }
}
