.blog--featured{
    article{
        .post_title{
            display: block !important;
        }

        .w-btn{
            text-align: left !important;
        }

        @media screen and (max-width: 767px){
            .post_image{
                & > div{
                    padding-bottom: 55% !important;
                }
            }
        }
    }
}

.blog--aside{
    article{
        .post_title{
            display: block !important;
        }

        .w-btn{
            text-align: left !important;
        }

        @media screen and (max-width: 767px){
            .post_image{
                & > div{
                    padding-bottom: 55% !important;
                }
            }
        }
    }
}