*{
    -webkit-font-smoothing: antialiased;
}

main{
    .wpb_text_column{
        .wpb_wrapper{
            a{
                text-decoration: none;
                border-bottom: 1px solid;
            }

            .g-breadcrumbs{
                a{
                    border-bottom: none;
                }
            }
        }
    }
}
.single-post{
    .post_custom_field.intro{
        a{
            text-decoration: none;
            border-bottom: 1px solid;
        }
    }
}

@media screen and (max-width: 600px){
    .l-section.height_medium {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }
}

@media screen and (max-width: 1024px){
    .tablet--fullwidth{
        grid-template-columns: 12fr !important;
    }
}
