
html.w-nav-open header nav.type_mobile ul.level_1{
    height: 100vh !important;
}
@media screen and (max-width: 1024px){
    header{
        .l-subheader.at_middle{
            .l-subheader-h{
                z-index: 999;

                ul.w-nav-list.level_1{
                    z-index: -1;
                    min-height: calc(100vh -  60px); 
                    min-width: 100vw;
                    margin-top: 60px;
                    background: $color__maes__green;
                    padding: 1rem 1.5rem;

                    ul.w-nav-list{
                        min-height: 100vh; 
                        min-width: 100vw;
                        transform: translateX(100%);
                        right: 0;
                        top: 0;
                        bottom: 0;
                        box-shadow: -1px 0 0 rgba(0,0,0,0.08);
                        position: fixed;
                        display: block;
                        padding: 1rem 1.5rem !important;
                        margin: 0;
                        transition: transform 0.2s cubic-bezier(.4,0,.2,1);
                        border-radius: 0; 
                        background: $color__maes__green !important;
                        color: $white;
                    }

                    li.opened{
                        & > ul.w-nav-list{
                            transform: translateX(0%);
                        }
                    } 

                    li.menu-item{
                        border: none !important; 

                        a:not(.w-btn){
                            color: $white !important;
                            padding: 0.5rem 0 !important;
                            line-height: 1.6em !important;
                            font-size: 14px !important; 
                            justify-content: space-between;
                            display: flex;
                            border-bottom: 1px solid rgba(255,255,255,0.2) !important;
                            font-weight: 400;

                            &:after{
                                display: none !important;
                            }

                            .w-nav-title{
                                &:before{
                                    display: none !important;
                                }
                            }

                            .w-nav-arrow{
                                &:before{
                                    font-weight: 400;
                                }
                            }
                        }


                        &.nav--button{
                            position: absolute; 
                            bottom: 70px; 
                            left: 0; 
                            width: 100%;
                            height: 60px;

                            .w-btn{
                                width: 100%;
                                margin: 0;
                                padding: 1rem;
                                height: 60px;
                                border-radius: 0;
                                line-height: 1.8 !important;
                                font-weight: 700; 
                                font-size: 1rem;
                            }
                        }
                    }

                    .w-nav-arrow:before {
                        content: '\f054';
                        font-weight: 700; 
                        font-size: 1rem;
                    }

                    .menu--goback{
                        font-size: 14px; 
                        display: block; 
                        width: 100%; 
                        position: relative;
                        color: $white;
                        padding: 0.5rem 0;
                        padding-left: 1rem;
                        border-bottom: 1px solid rgba(255,255,255,0.2) !important;
                        cursor: pointer;
                        font-weight: 700;
                        line-height: 1.5;
                        
                        &:after{
                            content: '\f053';
                            font-family: FontAwesome;
                            font-style: normal;
                            font-weight: 400; 
                            text-decoration: inherit;
                            color: $white;
                            font-size: 1rem;
                            position: absolute;
                            top: 0.3rem;
                            left: 0;
                        }
                    }
                }
            }
        }

        .menu--button {
            display: inline-block !important;
            padding: 5px !important;
            margin-top: 0 !important;
            line-height: 1 !important;

            a{
                margin: 0 !important;
            }
        }
    }
}