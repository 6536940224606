.pagination.navigation{
    margin-top: 3rem;
    
    .page-numbers{
        font-size: 1rem;
        line-height: 2rem;
        height: 2rem;
        width: 2rem;
        overflow: hidden;
        border-radius: 50%;
        transition: none;
    }
}