ul{
    margin-left: 20px; 
}

.ul--checklist{
    ul{
        margin: 0; 
        padding: 0; 
        list-style: none;
        
        li{
            padding-left: 2rem; 
            position: relative; 

            &:before{
                content: "\f00c";
                font-family: FontAwesome;
                font-style: normal;
                font-weight: normal;
                text-decoration: inherit;
                color: $color__maes__green;
                font-size: 1rem;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}

.color_alternate{
    .ul--checklist{
        ul{
            li{
                &:before{
                    color: $white;
                }
            }
        }
    }
}

.ul--col2{
    ul{
        display: flex; 
        flex-wrap: wrap;
        gap: 1rem 3rem;

        li{
            width: calc(50% - 1.5rem);

            @media screen and (max-width: 767px){
                width: 100%;
            }
        }
    }
}